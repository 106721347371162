import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Tabs, TypographyButton } from '@components';
import { useLoadYotpo } from '@services/yotpo';
import { capitalizeEachWord } from '@utils/strings';
import { useCustomer } from '@services/shopify';
import { LOCALE_CODES, useTranslation } from '@utils/index';
import styles from './AccountTabs.module.scss';

type AccountTabsProps = {
	defaultValue: string;
};

const getTranslatedText = translator => {
	return {
		myTabName: (tabName: string) => {
			return translator('my-tab-name', { tabName });
		},
	};
};

const AccountTab = ({ activeTab, tabName, membershipUrl }) => {
	const ref = useRef(null);
	const router = useRouter();
	const { translator } = useTranslation();
	const translations = getTranslatedText(translator);

	const scrollIntoView = () => ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

	useEffect(() => {
		if (ref?.current && activeTab === tabName) {
			scrollIntoView();
		}
	}, [ref?.current, activeTab]);

	return (
		<Tabs.Trigger
			asChild
			ref={ref}
			value={tabName}
			onClick={() => {
				if (tabName !== 'membership') {
					scrollIntoView();
					router.push(tabName !== 'rewards' ? `/account/${tabName}` : `/${tabName}`, undefined, { shallow: true });
					return;
				}
				window.open(membershipUrl);
			}}
		>
			<TypographyButton small>{capitalizeEachWord(translations.myTabName(tabName))}</TypographyButton>
		</Tabs.Trigger>
	);
};

const AccountTabs = ({ defaultValue = 'collection' }: AccountTabsProps) => {
	useLoadYotpo();
	const { locale } = useRouter();
	const { data: customer } = useCustomer();

	const showSubscription = locale === LOCALE_CODES.US;
	const showRewards = locale === LOCALE_CODES.US || locale === LOCALE_CODES.CA;
	let tabs = ['favorites', 'collection', 'orders', 'rewards', 'subscriptions', 'membership'];

	tabs = tabs.filter(
		tab =>
			(tab !== 'rewards' || showRewards) &&
			(tab !== 'subscriptions' || (customer?.subscriberId && showSubscription)) &&
			(tab !== 'membership' || (customer?.isMembershipVip && showSubscription))
	);

	const userId = customer?.id?.split('/').pop();
	const storeUrl = process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN;

	const membershipUrl = `https://${storeUrl}/apps/subscribfy-api/store/manage-membership-dashboard?cid=${userId}&exm=1&hash=${customer?.membershipHash}`;

	return (
		<Tabs value={defaultValue} activationMode='manual' className={styles.list}>
			<Tabs.List>
				{tabs.map(tabName => (
					<AccountTab key={tabName} activeTab={defaultValue} tabName={tabName} membershipUrl={membershipUrl} />
				))}
			</Tabs.List>
		</Tabs>
	);
};

export default AccountTabs;
