import { useQuery } from '@tanstack/react-query';
import { getOrders } from '@services/poms/operations/get-orders';
import { normalizeOrderHistory } from '@utils/normalizers';

type useOrders = { userId: boolean | string; page?: boolean | number; range?: boolean | number };

const useOrders = ({ userId, page, range = null }: useOrders) => {
	return useQuery(
		['orders', userId, page, range],
		async () => {
			const response = await getOrders({ id: userId, page, range });
			if (response === null) {
				return response;
			}
			return normalizeOrderHistory(response);
		},
		{ enabled: !!userId, refetchOnMount: false, refetchOnWindowFocus: false, cacheTime: 5 * 60 * 1000 }
	);
};

export default useOrders;
